import React from 'react';
import {
  Avatar,
  Tooltip,
  Badge
} from '@material-ui/core';
import FilmImage from './FilmImage';
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() =>({
  color:{
    backgroundColor: '#c02222',
  }
}))

function ProviderAvatar({badge, provider}){
  const classes = useStyles();

  return(
    <Tooltip disableFocusListener title={provider.provider_name} arrow>
      <Badge invisible={!badge} classes={{colorSecondary:classes.color}} color="secondary" badgeContent='ads'style={{margin:'4px'}}>
        <Avatar variant="rounded" style={{margin:'5px'}}>
          <FilmImage path={provider.logo_path} w={45} />
        </Avatar>
      </Badge>
    </Tooltip>
);
}

export default ProviderAvatar;
