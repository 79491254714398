import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  ImageListItem,
  ImageListItemBar,
  IconButton,
  Paper
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import FilmImage from './FilmImage';

const useStyles = makeStyles(theme => ({
  itemBox: {
    width: 500,
    height: 300,
    [theme.breakpoints.down('sm')]: {
      height: 300,
      width: 300,
    },
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  paper:{
    margin: 3,
    borderRadius:7,
    overflow:'hidden',
    display:'flex',
    justifyContent:'center',
    '&:hover':{
      opacity: 0.4
    }
  },
}));

function FilmCard({film, openModal, setId}){
  const classes = useStyles();

    return (
      <Paper elevation={3} className={classes.paper}>
        <ImageListItem className={classes.itemBox} onClick={()=>{
          openModal()
          setId({id: film.id, type:film.media_type})
        }}>
          <FilmImage w={780} renderHeight={300} path={film.backdrop_path} alt={film.title}/>
          <ImageListItemBar
            title={film.title ? film.title : film.name}
            subtitle={<span>Voto medio: {film.vote_average}</span>}
            actionIcon={
              <IconButton onClick={()=>{
                openModal()
                setId({id: film.id, type:film.media_type})
              }} aria-label={`info about ${film.title}`} className={classes.icon}>
                <InfoIcon />
              </IconButton>
            }
          />
        </ImageListItem>
      </Paper>
    );
}

export default FilmCard;
