import React, {
  useState,
  useEffect
} from 'react';
import {
  makeStyles
} from '@material-ui/core/styles';
import {
  Paper,
  Grid,
  Box,
  Snackbar,
  Divider,
  Fab,
  Icon,
  Zoom,
  Tooltip,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import SimpleBackdrop from './SimpleBackdrop';
import FilmImage from './FilmImage';
import ProviderAvatar from './ProviderAvatar'
import axios from 'axios';
import {flag, name} from 'country-emoji';

const useStyles = makeStyles(theme => ({
  paper:{
    width: '90vw',
    height: '80vh',
    margin: '10vh 5vw',
    position: 'relative',
    padding: '5px 5px 0 5px',
    color:'#fff',
    background: 'linear-gradient(to top, #283048, #859398)', /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  },
  container:{
    width:'100%',
    height:'100%',
    overflow:'hidden',
    [theme.breakpoints.down('lg')]:{
      overflow:'hidden auto',
    }
  },
  leftColBox:{
    maxHeight: 'calc(80vh)',
    overflow: 'hidden',
    [theme.breakpoints.down('lg')]:{
      // overflowX: 'visible',
      marginBottom:5,
    }
  },
  leftColContainer:{
    [theme.breakpoints.down('lg')]:{
      justifyContent: 'center',
      marginBottom:5,
    }
  },
  rightColBox:{
    maxHeight: 'calc(80vh)',
    overflow: 'auto',
    [theme.breakpoints.down('lg')]:{
      overflow:'visible',
      margin:'20px',
    }
  },
  provider_div:{
    display:'flex',
    justifyContent:'flex-start',
    margin: '10px',
    alignItems:'center',
    flexWrap: 'wrap',
  },
  root_provider_div:{
    display:'flex',
    justifyContent:'flex-start',
    alignItems:'center',
    flexWrap: 'wrap',
  }
}));



function ModalInfo({modalFilm, modalOpen, handleModalClose}) {
  const classes = useStyles();
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [filmData, setFilmData] = useState({});
  const [filmProviders, setFilmProviders] = useState([]);
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });

  const { vertical, horizontal, open } = snackbarState;

  useEffect(() => {

    if (modalFilm) {
      // // GET DATA ABOUT FILM
      axios.get(process.env.REACT_APP_API_SERVER +'/movie_info/' + modalFilm.type +'/' + modalFilm.id)
      .then(res => {
          setFilmData(res.data.filmData);
          res.data.filmData['watch/providers'] ? setFilmProviders(Object.entries(res.data.filmData['watch/providers'].results)) : setFilmProviders([]);
          setIsLoaded(true);
        }, (error) => {
            setIsLoaded(true);
            setError(error);
            setSnackbarState(prev => {return {...prev, open:true}})
          });
    }
  }, [modalFilm])

  const handleClose = () => {
    setSnackbarState({ ...snackbarState, open: false });
  };

  if (error) {
    return <Snackbar
        severity="error"
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
      ><Alert elevation={6} variant="filled" severity="error">{error.message}</Alert></Snackbar>;
  }
  else if (!isLoaded) {
    return <SimpleBackdrop />;
  } else {
    return(
      <Paper classes={{root:classes.paper}}>
        <Zoom in={modalOpen} unmountOnExit>
          <Fab size='small' style={{zIndex:'1755', position:'absolute', right:'-15px', top:'-15px'}} onClick={handleModalClose}>
            <CloseIcon/>
          </Fab>
        </Zoom>
        <div className={classes.container}>
        <Grid container spacing={1} direction="row" justifyContent="center" alignItems="stretch">
            <Grid item sm={12} lg={4} container className={classes.leftColContainer}>
              <Box className={classes.leftColBox} >
                 <FilmImage path={filmData.poster_path ? filmData.poster_path : filmData.backdrop_path} w={500} renderHeight='calc(750px)'/>
              </Box>
            </Grid>
            <Grid item sm={12} lg>
              <Box className={classes.rightColBox} >
                <div style={{maxWidth: '80vw'}}>

                  <Typography variant='h3' className={classes.title} style={{ wordWrap: "break-word" }}>{filmData.title ? filmData.title : filmData.name}</Typography>
                  <Box style={{display: 'flex', flexWrap:'wrap'}}>
                  {
                    filmData.genres && filmData.genres.map(genere => {
                      return <Typography variant='subtitle1' style={{marginRight:'7px'}} key={genere.name}>{genere.name} | </Typography>
                    })
                  }
                  </Box>
                  <p>{filmData.overview}</p>
                  {
                    filmData.credits && <Typography style={{marginBottom:7}} variant='subtitle2'><u><strong>Con:</strong></u>  {
                      filmData.credits.cast.map((actor, index)=> index<3 && actor.name + ' | ')
                    } ...e Altri</Typography>
                  }
                  <Divider />
                  {
                    filmData.release_date ?
                      <p>Data di uscita: <span>{filmData.release_date }</span> </p> :
                      <p>Ultima uscita: <span>{filmData.last_air_date }</span> </p>
                   }
                  {filmData.number_of_seasons && <p>{filmData.number_of_seasons} Stagioni |  {filmData.number_of_episodes} Episodi</p>}
                  <h3>In Italia disponibile su:</h3>
                  {
                    filmProviders.filter(c => c[0]==='IT').map(country => {
                        return <div className={classes.provider_div} key={country[0]}>
                              {
                                country[1].flatrate && Object.values(country[1].flatrate).map((provider, i) =>
                                  <ProviderAvatar key={i} provider={provider}/>)
                              }
                              {
                                country[1].ads && Object.values(country[1].ads).map((provider, i) =>
                                  <ProviderAvatar key={i} badge provider={provider}/>)
                                }
                            </div>
                    })
                  }
                  <Divider />
                  <h3>Negli altri paesi disponibile su:</h3>
                  <div className={classes.root_provider_div}>
                  {
                    filmProviders.filter(c => c[0]!=='IT').map(country => {
                      return (country[1].flatrate || country[1].ads) && <div className={classes.provider_div} key={country[0]}>
                       <Tooltip disableFocusListener title={name(country[0])} arrow>
                          <Icon variant="rounded" style={{marginRight:'10px'}}>{flag(country[0])}</Icon>
                        </Tooltip>
                        {
                          country[1].flatrate &&  Object.values(country[1].flatrate).map((provider,i) =>
                            <ProviderAvatar keys={i} provider={provider}/>)
                          }
                        {
                          country[1].ads && Object.values(country[1].ads).map((provider,i) =>
                          <ProviderAvatar keys={i} badge provider={provider}/>)
                        }
                      </div>
                    })
                  }
                  </div>
                </div>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Paper>
    );
  }
}
export default ModalInfo;
