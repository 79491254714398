import React, { useState, useEffect } from 'react';
import {
  useLocation
} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Modal
 } from '@material-ui/core';
import Search from './Search';
import ModalInfo from './ModalInfo';
import Footer from './Footer';

const useStyles = makeStyles(theme => ({
  root: {
    border: 0,
    color:'#ffffff',
    minHeight: '100vh',
    // padding: '0 30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflowX:'hidden',
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function App() {
  const classes = useStyles();
  const query = useQuery();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalFilm, setModalFilm] = useState({id:'' , type:'' });


useEffect(() => {
  const filmId = query.get('id') ?? '';
  const filmType = query.get('type') ?? '';
  if (filmId && filmType) {
    setModalFilm({id:filmId, type:filmType});
    handleModalOpen()
  }
  // eslint-disable-next-line
}, []);

function handleModalClose(){
  setModalOpen(false);
}

function handleModalOpen(){
  setModalOpen(true);
}

  return (
    <Container maxWidth={false} className={classes.root}>
      <Search open={handleModalOpen} setId={setModalFilm}/>

      <Modal open={modalOpen} onClose={handleModalClose} >
        <div><ModalInfo modalFilm={modalFilm} modalOpen={modalOpen} handleModalClose={handleModalClose}/></div>
      </Modal>
      <Footer />
    </Container>
);
}

export default App;
