import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import TelegramIcon from '@material-ui/icons/Telegram';

const useStyles = makeStyles(theme => ({
  root:{
    height:  80,
    color: '#FFFFFF',
    backgroundColor:'#c02222',
    // backgroundColor:'#F23005',

    marginTop: '15px',
    marginLeft: '-25px',
    minWidth:'100vw',
    textAlign:'center',
    boxShadow: '0 5px 20px rgba(0,0,0,.3)',
    display: 'flex',
    justifyContent:'center',
    alignItems: 'center',
    flexDirection:'column',
    padding: '5px'
  },
  up:{
    display: 'flex',
    justifyContent:'center',
    alignItems: 'center',
  },
  down:{
    display: 'flex',
    justifyContent:'center',
    alignItems: 'center',
  },
  logo:{
    height: 25,
    margin: '0 20px',
    [theme.breakpoints.down('sm')]:{
      height: 15
    }
  },
  text:{
    [theme.breakpoints.down('sm')]:{
      fontSize:15
    }
  },
  footerLink:{
    color: 'black',
    margin: '5px',
    display: 'flex',
    justifyContent:'center',
    alignItems: 'center',
  }
}))

export default function Footer(){
  const classes = useStyles();

  return <div className={classes.root}>
  <div className={classes.up}>
    <Typography variant='h6' className={classes.text}>Dati forniti da </Typography>
    <a href='https://www.themoviedb.org/'>
      <img className={classes.logo} alt='The Movie DB' src='https://www.themoviedb.org/assets/2/v4/logos/v2/blue_square_1-5bdc75aaebeb75dc7ae79426ddd9be3b2be1e342510f8202baf6bffa71d7f5c4.svg'/>
    </a>
    <Typography variant='h6' className={classes.text}>e</Typography>
    <a href='https://www.justwatch.com/'>
      <img className={classes.logo} alt='JustWatch' src='https://www.justwatch.com/appassets/img/logo/JustWatch-logo-large.webp'/>
    </a>
  </div>
  <div className={classes.down}>
    <a className={classes.footerLink} href='mailto:support@doveguardarlo.it'><HelpIcon style={{margin:'3px 3px'}} fontSize='small'/><Typography variant='caption'>Supporto</Typography></a>
    <form action="https://www.paypal.com/donate" method="post" target="_top" style={{margin:'0 10px'}}>
      <input type="hidden" name="hosted_button_id" value="S9LGHJ5GL6D64" />
      <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_SM.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
      <img alt="" border="0" src="https://www.paypal.com/en_IT/i/scr/pixel.gif" width="1" height="1" />
    </form>
    <a className={classes.footerLink} target='_blank' rel="noreferrer" href='https://t.me/Emidio21'><TelegramIcon style={{margin:'0 3px 0 0'}} fontSize='small'/><Typography variant='caption' >@Emidio21</Typography></a>
    {
    // \<a className={classes.footerLink} target='_blank' rel="noreferrer" href="https://www.iubenda.com/privacy-policy/20696151" title="Privacy Policy "><Typography variant='caption' >Privacy Policy</Typography></a>
      }
</div>

  </div>
}
