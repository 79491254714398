import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import { Box, Typography,  } from '@material-ui/core';
import WarningTwoToneIcon from '@material-ui/icons/WarningTwoTone';

const useStyles = makeStyles(theme => ({
  box: {
    marginTop: 40,
    padding: 20,
    backgroundColor: "#c02222",
    marginBottom: -80,
  }
}))

export default function Manutenzione(){
  const classes = useStyles();

  return <Box align='center' className={classes.box}>
      <WarningTwoToneIcon />
      <Typography  variant='h6'>
        Servizio attualmente in manutenzione.<br/>
      Tornerà disponibile al più presto</Typography>
  </Box>
}
