import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles' ;
import {
  Typography,
  TextField,
  Box,
  ImageList,
  InputAdornment,
  Snackbar,
  CircularProgress,
  Zoom,
  Fab,
  Paper
 } from '@material-ui/core';
 import SearchIcon from '@material-ui/icons/Search';
 import { Pagination, Alert, } from '@material-ui/lab'
 import axios from 'axios';
 import FilmCard from './FilmCard';
 import Manutenzione from './Manutenzione';
 import TelegramIcon from '@material-ui/icons/Telegram';
 import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
 import WarningIcon from '@material-ui/icons/Warning';
 import useScrollTrigger from '@material-ui/core/useScrollTrigger';


 const useStyles = makeStyles(theme => ({
   box:{
     paddingTop: props => props.padding,
     display:'flex',
     flexDirection: 'column',
     alignItems:'center',
     justifyContent:'center'
   },
   text:{
     fontWeight:'700',
     [theme.breakpoints.down('sm')]:{
       fontSize:'15px',
     }
   },
   imageList: {
    paddingTop: 100,
    justifyContent: 'center',
  },
  textSearch:{
    width: '300px',
    backgroundColor: '#fff',
    borderRadius:'5px',
    [theme.breakpoints.down('sm')]:{
      width: '60vw'
    }
  },
  textSearchExpanded:{
    width: '600px',
    backgroundColor: '#fff',
    borderRadius:'5px',
    [theme.breakpoints.down('sm')]:{
      width: '90vw'
    }
  },
  telegramButton:{
    textDecoration: 'none',
    background: '#0088cc',
    color:'#fff',
    padding: '10px 15px',
    borderRadius: '7px',
  },
  telegramText:{
    display:'flex',
    alignItems:"flex-end"
  },
  fab: {
    position: 'fixed',
    bottom: '50px',
    right: '100px',
    [theme.breakpoints.down('sm')]:{
      right: theme.spacing(2),
    }
  },
  noResults:{
    padding: '10px 20px',
    backgroundColor: '#c02222',
    color: '#fff',
    marginTop: '-50px',
    marginBottom: '20px',
    opacity: '0.9',
    maxWidth:'80vw',
    overflow: 'auto'
  },
  filmConsigliati:{
    display: 'block',
    backgroundColor:"#212332",
    padding: 10,
    paddingLeft: 30,
    paddingRight: 30,
    borderRadius: 10,
    textAlign: 'center',
    color: '#abb7c4',
    fontWeight: 'bold'
  },
  pagination:{
    '& .MuiPaginationItem-page.Mui-selected':{
      backgroundColor: '#c02222',
      color:'#fff'
    },
    '& .MuiPaginationItem-root':{
      color: '#c02222'
    }
  }
 }));


function Search({open, setId}){
  const [textValue, setTextValue] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [pages, setPages] = useState(1);
  const [page, setPage] = useState(1);
  const props = {padding:isSearching ? 50 : 200,}
  const classes = useStyles(props);

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(true);
  const [snackbarState, setSnackbarState] = useState({
    snackbarOpen: false,
    vertical: 'top',
    horizontal: 'center',
  });
const { vertical, horizontal, snackbarOpen } = snackbarState;

const scrollToTop = useScrollTrigger();

  useEffect(()=>{
    if (textValue.length >= 3) {
      setIsSearching(true)
      setIsLoaded(false)
      setPages(0)
      axios.get(process.env.REACT_APP_API_SERVER + '/multi_search/'+textValue + '/' + page)
      .then(result => {
        if (result.data.results){
          setSearchResult(result.data.results);
          setPages(result.data.results!==0 ? result.data.total_pages : 0);
          setIsLoaded(true);
      } else {
        setIsLoaded(true);
        const error={message:'Errore nel caricamento', name:'Errore caricamento'}
        setError(error);
        setSnackbarState(prev => {return {...prev, snackbarOpen:true}})
        }
      },error =>{
        setIsLoaded(true);
        setError(error);
        setSnackbarState(prev => {return {...prev, snackbarOpen:true}})
      }
    ).catch(error =>{
      setIsLoaded(true);
      setError(error);
      setSnackbarState(prev => {return {...prev, snackbarOpen:true}})
    });
  } else{
    setIsSearching(false)
    setIsLoaded(true)
  }
  }, [textValue, page])

  function onChangeText(e){
    const {value} = e.target;
    setTextValue(value);
    setPage(1);
    value.length>2 && setIsLoaded(false)
  }

  function handleClick(){
    setExpanded(!expanded);
  }

  const handlePageChange = (event, value) => {
  setPage(value !==0 ? value : 1);
};

const handleSnackbarClose = () => {
  setSnackbarState({ ...snackbarState, open: false });
};

const handleTopClick = (event) => {
   const anchor = (event.target.ownerDocument || document).querySelector('#textField');

   if (anchor) {
     anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
     
   }
 }

  return(
    <Box align='center' className={classes.box} >
      <a href='/'><img style={{marginBottom:'10px', width:'300px'}} alt='logo' src={window.location.origin + '/logo.png'}/></a>
        <TextField InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          )}} className={!expanded ? classes.textSearch : classes.textSearchExpanded} onBlur={handleClick} onFocus={handleClick}
          onChange={onChangeText} placeholder='Cerca un Film, una SerieTV o un attore' variant='outlined' margin="dense" value={textValue} id='textField' disabled={process.env.REACT_APP_MANUTENZIONE===true}/>

      {
        error && <Snackbar
              severity="error"
              anchorOrigin={{ vertical, horizontal }}
              open={snackbarOpen}
              onClose={handleSnackbarClose}
            ><Alert elevation={6} variant="filled" severity="error">{error.message}</Alert></Snackbar>
        }

        {
          process.env.REACT_APP_MANUTENZIONE===true && <Manutenzione />
        }
        {!isLoaded && <CircularProgress style={{marginTop:50}} />}

        {isLoaded && !error && <ImageList className={classes.imageList} >

          { searchResult.map(film => <FilmCard key={film.id} film={film} openModal={open} setId={setId}/>)}

        </ImageList>}
      {pages > 1 && <Pagination className={classes.pagination} style={{ marginTop:'20px'}} count={pages} howFirstButton showLastButton page={page} onChange={handlePageChange}/> }

      {(isSearching && searchResult.length===0 && isLoaded) && <Paper elevation={3} className={classes.noResults}><WarningIcon fontSize='small'/><Typography variant='subtitle1'>Nessun Risultato trovato per "<i>{textValue}</i>"</Typography></Paper>}

      <a rel="noreferrer noopener" target='_blank' href='https://t.me/filmchecker_bot' className={classes.telegramButton} style={{marginTop: '50px'}}>
        <Typography variant='subtitle2' className={classes.telegramText}><TelegramIcon style={{marginRight:'5px'}}/> Prova anche il Bot Telegram</Typography>
      </a>

      <a rel="noreferrer noopener" target='_blank' href='https://www.filmconsigliati.com/' className={classes.filmConsigliati} style={{textDecoration:'none', marginTop: '50px'}}>
        <Typography variant='h6' >NON SAI COSA GUARDARE? </Typography><img className={classes.logo} alt='Film Consigliati' src='https://www.filmconsigliati.com/wp-content/uploads/2020/12/Logo-e1607883514225.png'/>
      </a>


      <Zoom
         in={scrollToTop}
         timeout={500}
         unmountOnExit
       >
         <Fab aria-label='Back to top' size='small' className={classes.fab} onClick={handleTopClick}>
           <KeyboardArrowUpIcon />
         </Fab>
       </Zoom>
    </Box>
  );
}


export default Search;
